<template>
  <div class="wrapper">
    <div class="body">

      <div class="row mr-0 ml-0" style="display: none">
        <div class="col-12 tournament-name">
          <arrow-left-icon :size="iconSize" fill-color="white" @click="goBack"></arrow-left-icon>
          <span>
            {{ getSportName(fixture.sport_id) }} / {{ fixture.country }} / {{ fixture.tournament }}
          </span>
        </div>
      </div>

      <!-- Iframe Section -->
      <!-- <div class="iframe-container">
        <iframe v-bind:src="iframeUrl" frameborder="0" class="full-size-iframe"></iframe>
      </div> -->
      <!-- Outcome Section -->
      <Outcome v-for="(m, index) in visibleMarkets" :key="getKey(index)" :market_id="m.market_id"
        :market_name="m.market_name" :specifier="m.specifier" :status_name="m.status_name" :status="m.status"
        :fixture="fixture" :producer_status="fixture.producer_status" :producer_id="fixture.producer_id"
        :outcome="m.outcomes" />

      <bottom-navigation active-bets="0" page="match"></bottom-navigation>

    </div>
  </div>
</template>
<script>

import BottomNavigation from './BottomNavigation'
import Outcome from './Outcome'
import fixtures from "@/services/fixtures";
//import mqtt from "mqtt";

(function (a, b, c, d, e, f, g, h, i) {
  a[e] || (i = a[e] = function () { (a[e].q = a[e].q || []).push(arguments) }, i.l = 1 * new Date, i.o = f,
    g = b.createElement(c), h = b.getElementsByTagName(c)[0], g.async = 1, g.src = d, g.setAttribute("n", e), h.parentNode.insertBefore(g, h)
  )
})(window, document, "script", "https://widgets.sir.sportradar.com/c579505099810db0db4c76b99aa21921/widgetloader", "SIR", {
  theme: false, // using custom theme
  language: "en"
});

export default {
  name: 'Match',
  components: {
    BottomNavigation,
    Outcome
  },
  data: function () {
    return {
      iconSize: 24,
      match_id: 0,
      game_id: 0,
      //fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0
      },
      //totals_18: [],
      //totals_238: [],
      live: false,
      seconds: 0,
      mqttClient: false,
      iframeUrl: "",
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {

        // react to route changes...
        document.title = to.params.name.replace(/[^a-z0-9+]+/gi, " ");
        document.description = "The best boosted odds for " + to.params.name.replace(/[^a-z0-9+]+/gi, " ") + " get free bonus, freebets and many cashback offers ";
        this.game_id = to.params.game_id;//this.baseConverter(to.params.game_id, 36, 10);
        this.live = to.params.status === 'live';
        this.getMatch();
        /*
        var vm = this;

        this.$store.dispatch("getFixture", this.game_id);

        setInterval(function (){

          vm.$store.dispatch("getFixture", vm.game_id);

        },1000);
        */

      }
    },
  },
  mounted: function () {

    this.autoRefreshUI(this.$vnode.tag);
    this.$store.dispatch("setCurrentPage", "match");
    /*
    var vm =this;

    setInterval(function (){

      vm.getMatch();

    },1000 * 30);
  */

  },
  computed: {
    marketGroups: function () {

      return this.$store.state.market_groups
    },
    visibleMarkets: function () {

      //return this.fixture.market;
      return this.$store.state.fixture.market;

    },
    fixture: function () {

      //return this.fixture.market;
      return this.$store.state.fixture;

    },
    totals_18: function () {

      //return this.fixture.market;
      return this.$store.state.fixture.totals_18;

    },
    totals_238: function () {

      //return this.fixture.market;
      return this.$store.state.fixture.totals_238;

    },
    totals_166: function () {

      //return this.fixture.market;
      return this.$store.state.fixture.totals_166;

    },
    collapsibleMarkets: function () {

      var max = 9;
      var x = 0;
      var markets = [];

      this.jQuery.each(this.$store.state.fixture.market, function (k, v) {

        x++;

        if (x > max) {

          markets.push(v);
        }

      });

      return [];
    },
    home_team: function () {

      return this.getHomeCompetitorName(this.$store.state.fixture.name)
    },
    away_team: function () {

      return this.getAwayCompetitorName(this.$store.state.fixture.name)
    },
    event_time: function () {

      var minutes = parseInt(this.$store.state.fixture.seconds / 60);
      //var seconds = this.seconds % 60;

      var min = minutes;

      if (minutes < 10) {

        min = "0" + min;
      }

      return min + "'";

    },
  },
  methods: {

    initMqtt: function () {

      /*
      //return this.registerPoll();

      var vm = this;

      if (this.mqttClient !== false) {

        this.mqttClient.end();

      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      }

      var topic_match = 'topic/producer-3/sport-' + this.fixture.sport_id + '/status/match-' + this.fixture.match_id
      if (this.live) {

        topic_match = 'topic/producer-1/sport-' + this.fixture.sport_id + '/status/match-' + this.fixture.match_id
      }

      var client = mqtt.connect(process.env.VUE_APP_URL_MQTT_HOST, options);

      client.on('connect', function () {
        client.subscribe(topic_match, function (err) {
          if (!err) {

            console.log('subscribed to topic ' + topic_match);

          }
        })

        var topic2 = 'topic/producer';
        client.subscribe(topic2, function (err) {
          if (!err) {

            console.log('subscribed to topic ' + topic2);

          }
        })

      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      })

      //this.poll(topic_betstop);

      this.mqttClient = client;
      */

    },

    listenForOddsChange: function () {

      var vm = this;

      vm.EventBus.$on('match:reload:' + this.fixture.match_id, function (updates) {

        vm.updateFixture(updates);

      });

    },

    getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'outcomes-id-' + index + '-');

    },
    getColumnWidth: function (a) {

      if (a.length === 2) {

        return 'col-6';
      }

      if (a.length === 3) {

        return 'col-4';
      }

      return 'col-auto'

    },
    getHomeCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }

      return matchName.split('vs.')[0];
    },
    getAwayCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }
      return matchName.split('vs.')[1];
    },
    getMatch: function () {

      var vm = this;

      var path = "/game/{game_id}";

      path = path.replace("{game_id}", this.game_id);

      fixtures.get(path)
        .then(res => {

          console.log('fixtue match data', res.data)
          var fixture = res.data;
          var initial_fixture = res.data;

          if (parseInt(initial_fixture.producer_id) !== 3) {

            vm.live = true;

          } else {

            vm.live = false;

          }

          // format over/under

          var formatted_18 = {}
          var formatted_238 = {}
          var formatted_166 = {}

          var totals_18 = formatted_18;
          var totals_238 = formatted_238;
          var totals_166 = formatted_166;

          fixture.totals_18 = totals_18;
          fixture.totals_238 = totals_238;
          fixture.totals_166 = totals_166;

          if (fixture.event_time.length > 0) {

            var p = fixture.event_time.split(':');
            var minutes = p[0];
            var seconds = p[1];
            seconds = parseInt(seconds);
            seconds = seconds + parseInt(minutes) * 60;
            //vm.seconds = seconds;
            fixture.seconds = seconds;

          }

          vm.$store.dispatch("setFixture", fixture);

          /*
          // eslint-disable-next-line no-undef
          SIR("addWidget", ".sr-widget-1", "match.lmtPlus", {
            scoreboard: "extended",
            detailedScoreboard: "disable",
            matchId: vm.fixture.match_id,
          });

          */

          vm.iframeUrl = "https://widget.maybets.com?match_id=" + vm.fixture.match_id;

          vm.initMqtt();
          vm.listenForOddsChange();

          vm.autoRefreshUI(vm.$vnode.tag);

          setTimeout(function () {

            vm.autoRefreshUI(vm.$vnode.tag);

          }, 1000)

          setTimeout(function () {

            vm.autoRefreshUI(vm.$vnode.tag);

          }, 2000)

        })
        .catch(error => {

          console.log(error)

        })
    },
    updateFixture: function (updates) {

      //console.log(JSON.stringify(updates))
      var vm = this;
      var fixture = updates;
      var initial_fixture = updates;

      vm.live = parseInt(initial_fixture.producer_id) === 1;

      var formatted_18 = {}
      var formatted_238 = {}
      var formatted_166 = {}

      vm.jQuery.each(initial_fixture.market, function (k, v) {

        if (parseInt(v.market_id) === 18) {

          vm.jQuery.each(v.outcome, function (k1, v1) {

            var outcome_id = v1.outcome_id;
            var specifier = v1.specifier; // 0.5.1.5,2.5,3.5,4.5
            var over = "over";
            var under = "under";

            //chck if key does not exist and create it
            if (formatted_18[specifier] === null || !formatted_18[specifier] || formatted_18[specifier] === false || formatted_18[specifier] === undefined) {

              formatted_18[specifier] = {};
              formatted_18[specifier][over] = {};
              formatted_18[specifier][under] = {};

            }

            if (parseInt(outcome_id) === 13) {

              formatted_18[specifier][under] = v1;

            }

            if (parseInt(outcome_id) === 12) {

              formatted_18[specifier][over] = v1;

            }

            var id = vm.getOddID(initial_fixture.match_id, v.market_id, v1.outcome_id, v1.specifier, 'odd');
            var oddsChangedPayload = {};
            oddsChangedPayload.id = id;
            oddsChangedPayload.odds = v1.odd;
            oddsChangedPayload.status = v1.status;
            oddsChangedPayload.active = v1.active;
            oddsChangedPayload.previous_odds = v1.odd;
            vm.EventBus.$emit('odds:changed', oddsChangedPayload);

          })

        }
        else if (parseInt(v.market_id) === 238) {

          vm.jQuery.each(v.outcome, function (k1, v1) {

            var outcome_id = v1.outcome_id;
            var specifier = v1.specifier;
            var over = "over";
            var under = "under";

            if (formatted_238[specifier] === null || !formatted_238[specifier] || formatted_238[specifier] === false || formatted_238[specifier] === undefined) {

              formatted_238[specifier] = {};
              formatted_238[specifier][over] = {};
              formatted_238[specifier][under] = {};

            }

            if (parseInt(outcome_id) === 13) {

              formatted_238[specifier][under] = v1;

            }

            if (parseInt(outcome_id) === 12) {

              formatted_238[specifier][over] = v1;

            }

            var id = vm.getOddID(initial_fixture.match_id, v.market_id, v1.outcome_id, v1.specifier, 'odd');
            var oddsChangedPayload = {};
            oddsChangedPayload.id = id;
            oddsChangedPayload.odds = v1.odd;
            oddsChangedPayload.status = v1.status;
            oddsChangedPayload.active = v1.active;
            oddsChangedPayload.previous_odds = v1.odd;
            vm.EventBus.$emit('odds:changed', oddsChangedPayload);

          })

        }
        else if (parseInt(v.market_id) === 166) {

          vm.jQuery.each(v.outcome, function (k1, v1) {

            var outcome_id = v1.outcome_id;
            var specifier = v1.specifier;
            var over = "over";
            var under = "under";

            if (formatted_166[specifier] === null || !formatted_166[specifier] || formatted_166[specifier] === false || formatted_166[specifier] === undefined) {

              formatted_166[specifier] = {};
              formatted_166[specifier][over] = {};
              formatted_166[specifier][under] = {};

            }

            if (parseInt(outcome_id) === 13) {

              formatted_166[specifier][under] = v1;

            }

            if (parseInt(outcome_id) === 12) {

              formatted_166[specifier][over] = v1;

            }

            var id = vm.getOddID(initial_fixture.match_id, v.market_id, v1.outcome_id, v1.specifier, 'odd');
            var oddsChangedPayload = {};
            oddsChangedPayload.id = id;
            oddsChangedPayload.odds = v1.odd;
            oddsChangedPayload.status = v1.status;
            oddsChangedPayload.active = v1.active;
            oddsChangedPayload.previous_odds = v1.odd;
            vm.EventBus.$emit('odds:changed', oddsChangedPayload);

          })

        }
        else {

          vm.jQuery.each(v.outcome, function (k1, v1) {

            var id = vm.getOddID(initial_fixture.match_id, v.market_id, v1.outcome_id, v1.specifier, 'odd');
            var oddsChangedPayload = {};
            oddsChangedPayload.id = id;
            oddsChangedPayload.odds = v1.odd;
            oddsChangedPayload.status = v1.status;
            oddsChangedPayload.active = v1.active;
            oddsChangedPayload.previous_odds = v1.odd;
            vm.EventBus.$emit('odds:changed', oddsChangedPayload);

          });
        }

      });

      var totals_18 = formatted_18;
      var totals_238 = formatted_238;
      var totals_166 = formatted_166;

      fixture.totals_18 = totals_18;
      fixture.totals_238 = totals_238;
      fixture.totals_166 = totals_166;

      if (fixture.event_time.length > 0) {

        var p = fixture.event_time.split(':');
        var minutes = p[0];
        var seconds = p[1];
        seconds = parseInt(seconds);
        seconds = seconds + parseInt(minutes) * 60;
        //vm.seconds = seconds;
        fixture.seconds = seconds;
      }

      vm.$store.dispatch("setFixture", fixture);
      vm.autoRefreshUI(vm.$vnode.tag);

    },
    getOddDirectionClass: function (outcome) {

      if (parseInt(outcome.active) === 0 || parseInt(outcome.status) != 1) {

        return '';
      }

      if (parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds)) {

        return 'arrow green up';

      }

      return 'arrow red down';

    }
  },
  beforeDestroy: function () {
    console.log("beforeDestroy")

    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }
  }
}
</script>

<style>
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.iframe-container {
  width: 100%;
  height: 56vh;
  /* Adjust as needed based on your design */
  overflow: hidden;
  /* Prevent iframe content from overflowing */
  margin-bottom: 10px;
  /* Optional: Add margin to separate sections */
  display: flex;
  flex-direction: column;
}

.full-size-iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 568px) {
  .iframe-container {
    height: 50vh;
    /* Adjust for smaller screens */
  }
}

/* Example of additional responsive adjustments */
@media (max-width: 576px) {
  .iframe-container {
    height: 47vh;
  }
}
</style>